<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="cardState">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0">
                        <span class="font-weight-bold">
                            {{item.registration}} • <span class="gold--text">{{item.id}}</span> •
                            <span class="gold white--text px-3 rounded">{{item.role}}</span>
                        </span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" :sm="grid.sm" :md="grid.md" :lg="grid.lg" style="width: calc(100% / 3);">
                        <v-icon small color="gold" class="pr-1 pb-1">mdi-account-circle</v-icon>
                        <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ item.fullname }}</span>
                    </v-col>
                    <v-col cols="12" :sm="grid.sm" :md="grid.md" :lg="grid.lg" style="width: calc(100% / 3);">
                        <v-icon small color="gold" class="pr-2 pb-1">mdi-email</v-icon>
                        <span class="body-2">{{ item.email }}</span>
                    </v-col>
                    <v-col cols="12" :sm="grid.sm" :md="grid.md" :lg="grid.lg" style="width: calc(100% / 3);" >
                        <v-icon small color="gold" class="pr-2 pb-1">mdi-phone</v-icon>
                        <span class="body-1 font-weight-bold">{{ item.phone }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "UserCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        grid: {sm: 12, md: 4, lg: 4}
    }),
    computed: {
        cardState() {
            if (!this.item.active) {
                return 'state-grey'
            } else {
                return ''
            }
        }
    }
}
</script>